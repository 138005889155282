import React from "react";
import { useDispatch } from "react-redux";
import { Redirect, Route } from "react-router-dom";

import { showErrorSnackbar } from '../../../actions/snackbar';
import { authKey_local_storage_key } from "../../../helpers/common";

const AppRoute = ({
  component: Component,
  isAuthProtected,
  ...rest
}) => {

  const authToken = localStorage.getItem(authKey_local_storage_key);

  const dispatch = useDispatch();

  if (isAuthProtected && !authToken) {
    dispatch(showErrorSnackbar('You must be login to proceed!'));
  }

  return (
    <Route
      {...rest}
      render={(props) =>
        isAuthProtected && authToken ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{ pathname: "/login", state: { from: props.location } }}
          />
        )
      }
    />
  );
};

export default AppRoute;
