import ajax from './ajax';

export function getItemList(itemName, queryParamsObj, perPage, page) {
  return ajax(`/${itemName}?${queryParamsObj}`);
}

export function getProduct(productId) {
  return ajax(`/products/${productId}`);
}

export function getSimilarProducts(productId) {
  return ajax(`/products?ProductsSearch[similar_of]=${productId}`);
}

export function postReview(params) {
  return ajax('/product_reviews', { method: 'POST', data: params });
}

export function getAllReviews(productId) {
  return ajax(`/product_reviews?product_id=${productId}`)
}
