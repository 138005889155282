import React from 'react';
import { Box, Snackbar } from '@material-ui/core';
import MuiAlert from '@material-ui/lab/Alert';
import { useDispatch } from 'react-redux';
import { hideSnackbarMessage } from '../../../actions/snackbar';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export default function SimpleSnackbar({ message, open, action, severity }) {
  const dispatch = useDispatch();
  const handleClose = () => dispatch(hideSnackbarMessage());

  return (
    <Snackbar
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right'
      }}
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
    >
      <Alert onClose={!action && handleClose} severity={severity} style={{ fontFamily: 'Raleway' }}>
        <Box display="flex" justifyContent="space-between" alignItems="center">{message}
          <Box ml={3}>{action}</Box>
        </Box>
      </Alert>
    </Snackbar>
  );
}
