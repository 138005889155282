import React from "react";
import useStyles from "./styles";
import {
  convertToSlug,
  getCategoriesByParentName,
  getMenusByCategory,
  imageUrl,
} from "../../../helpers/common";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import Collapse from "@material-ui/core/Collapse";
import ExpandLess from "@material-ui/icons/ExpandLess";
import ExpandMore from "@material-ui/icons/ExpandMore";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

export default function SideBar({ sideBarRef, open, setOpen }) {
  const classes = useStyles();
  const history = useHistory();
  const menus = useSelector((state) => state.menus.menus);
  const topMenus = getMenusByCategory(menus, "top-menu-dropdown") ?? [];
  const labeledProducts =
    useSelector((state) => state.products.labeledProducts) ?? {};
  const categories = useSelector((state) => state.categories.data) ?? [];

  const [dropDownOpen, setDropDownOpen] = React.useState(false);

  const [currentDropdownIndex, setCurrentDropdownIndex] = React.useState(null);

  const handleClick = (index) => {
    setCurrentDropdownIndex(index);
    setDropDownOpen(!dropDownOpen);
  };

  const productClick = (item) => {
    history.push(`/product/${item.id}/${convertToSlug(item.name)}`);
    setDropDownOpen(!dropDownOpen);
    setOpen(!open);
  };

  const categoryClick = (item) => {
    history.push(`/products/${item.id}/1/${convertToSlug(item.name)}`);
    setDropDownOpen(!dropDownOpen);
    setOpen(!open);
  };
  return (
    <div
      className={`${classes.sideBarMenuFull} ${
        open ? classes.sideBarMenuFullActive : ""
      }`}
    >
      <div
        ref={sideBarRef}
        className={`sideBarMenu ${classes.sideBarMenu} ${
          open ? classes.sideBarMenuActive : ""
        }`}
      >
        <div className={classes.close}>
          <button onClick={() => setOpen(!open)} className={`navbar-toggler `}>
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
          </button>
        </div>
        <div className={classes.mobileMenus}>
          {topMenus.map((menu, index) => (
            <div key={`top-menu-${menu.id}`}>
              <List
                component="nav"
                aria-labelledby="nested-list-subheader"
                className={classes.root}
              >
                <ListItem button onClick={() => handleClick(index)}>
                  <ListItemText primary={menu.name} />
                  {currentDropdownIndex === index ? (
                    <>{dropDownOpen ? <ExpandLess /> : <ExpandMore />}</>
                  ) : <ExpandMore />}
                </ListItem>
              </List>
              <Collapse
                in={currentDropdownIndex === index ? dropDownOpen : false}
                timeout="auto"
                unmountOnExit
              >
                <List component="div" disablePadding>
                  {menu.url.includes("#labels/")
                    ? labeledProducts[menu.name]?.map((item) => {
                        return (
                          <ListItem
                            key={item.id}
                            button
                            className={classes.nested}
                            onClick={() => productClick(item)}
                          >
                            <div className="d-flex align-items-center">
                              <img
                                className={classes.subCategoryImage}
                                src={imageUrl(
                                  item.main_image,
                                  `products/${item.id}`
                                )}
                                alt="product-img"
                              />
                              <div>
                                <ListItemText primary={item.name} />
                                <p className={classes.productPrice}>
                                  &#x20B9;{item.selling_price}
                                </p>
                              </div>
                            </div>
                          </ListItem>
                        );
                      })
                    : getCategoriesByParentName(categories, menu.name)?.map(
                        (category) => (
                          <ListItem
                            key={category.id}
                            button
                            className={classes.nested}
                            onClick={() => categoryClick(category)}
                          >
                            {/* <Link to={`/products/${category.id}/1/${convertToSlug(category.name)}`}> */}
                            <div className="d-flex align-items-center">
                              <img
                                className={classes.subCategoryImage}
                                src={imageUrl(category.image, "categories")}
                                alt="category-img"
                              />
                              <ListItemText primary={category.name} />
                            </div>
                            {/* </Link> */}
                          </ListItem>
                        )
                      )}
                </List>
              </Collapse>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
