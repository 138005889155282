import React, { useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import AccountDropdown from "./AccountDropdown";
import { Link, useHistory } from "react-router-dom";
import Navbar from "react-bootstrap/Navbar";
import { Container, Nav } from "react-bootstrap";
import { logout } from "../../../actions/customer";
import { useCallback } from "react";
import { showErrorSnackbar } from "../../../actions/snackbar";
import { getHotProducts, getOfferProducts } from "../../../actions/products";
import { dataUrl } from "../../../helpers/common";
import DropdownMenu from "./DropdownMenu";
import SideBar from "../SideBar";

const Header = () => {
  const cartData = useSelector((state) => state.cart.data);
  const token = localStorage.getItem("metcraftchitra_authToken");
  const dispatch = useDispatch();
  const history = useHistory();
  const assets = useSelector((state) => state.assets.data);
  const [open, setOpen] = React.useState(false);
  const sideBarRef = useRef();
  const menuBtnRef = useRef();

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }

  useEffect(() => {
    const handler = (event) => {
      if (
        !sideBarRef.current.contains(event.target) &&
        !menuBtnRef.current.contains(event.target)
      ) {
        setOpen(false);
      }
    };
    document.addEventListener("mousedown", handler);
    return () => {
      document.removeEventListener("mousedown", handler);
    };
  });

  const websiteLogo = assets?.find((asset) => asset.name === "website_logo");

  const handleLogout = useCallback(() => {
    dispatch(logout());
    dispatch(showErrorSnackbar("You are logged out!"));
    history.push("/login");
  }, [dispatch, history]);

  useEffect(() => {
    dispatch(getHotProducts(`ProductsSearch[product_labels]=Hot`));
    dispatch(getOfferProducts(`ProductsSearch[product_labels]=Offer`));
  }, [dispatch]);

  return (
    <>
      <Navbar bg="light" expand="lg" className="my_navbar">
        <div className="container justify-content-end">
          <ul className="top_right">
            {/* <li>
                      <Link to='/profile'>
                        <i className='fa fa-user' /> Account
                      </Link>
                    </li> */}
            <li>
              <AccountDropdown />
            </li>
            {!token ? (
              <li>
                <Link to="/login">
                  <i className="fa fa-sign-in" /> Login
                </Link>
              </li>
            ) : (
              <li>
                <Link onClick={handleLogout}>logout</Link>
              </li>
            )}
            <li>
              <Link to="/cart">
                <i className="fa fa-shopping-cart" />
                <span className="noti_count">{cartData.length}</span> My Cart
              </Link>
            </li>
          </ul>
        </div>
        <Container>
          <Link onClick={scrollToTop} className="navbar-brand" to="/">
            <img src={`${dataUrl}/assets/${websiteLogo?.file_name}`} alt="" />
          </Link>
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="navbar-nav ml-auto">
              <DropdownMenu sideBarRef={sideBarRef} open={open} />
            </Nav>
          </Navbar.Collapse>

          <button
            ref={menuBtnRef}
            onClick={() => setOpen(!open)}
            aria-controls="basic-navbar-nav"
            type="button"
            aria-label="Toggle navigation"
            className={`navbar-toggler ${open ? "" : "collapsed"}`}
          >
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
            <span className="navbar-toggler-icon"></span>
          </button>
          <SideBar menuBtnRef={menuBtnRef} open={open} setOpen={setOpen} sideBarRef={sideBarRef} />
        </Container>
      </Navbar>
    </>
  );
};

export default Header;
