import React from "react";
import { Link } from "react-router-dom";
import makeStyle from "./styles";
import { imageUrl, convertToSlug } from "../../../helpers/common";

const CategoryBox = ({ category }) => {
  const classes = makeStyle();

  const link = `/products/${category.id}/1/${convertToSlug(category.name)}`;

  return (
    <>
      <Link to={link} className={`categories-box ${classes.categoriesBox}`}>
        <img
          className={classes.boxImage}
          src={imageUrl(category.image, "categories")}
          alt="category-img"
        />
        <p className={classes.categoryName}>{category.name}</p>
      </Link>
    </>
  );
};

export default CategoryBox;
