import { lazy } from "react";

const HomePage = lazy(() => import("../components/HomePage"));
const ProductListing = lazy(() => import("../components/ProductListing"));
const SignUp = lazy(() => import("../components/SignUp"));
const Login = lazy(() => import("../components/Login"));
const Profile = lazy(() => import("../components/Profile"));
const Cart = lazy(() => import("../components/Cart"));
const Shipping = lazy(() => import("../components/Shipping"));
const Checkout = lazy(() => import("../components/Checkout"));
const CmsPage = lazy(() => import("../components/CmsPage"));
const ContactUs = lazy(() => import("../components/ContactUs"));
const SingleProduct = lazy(() => import("../components/SingleProduct"));
const ChangePassword = lazy(() => import("../components/Profile/ChangePassword"));
const ForgetPassword = lazy(() => import("../components/ForgetPassword"));
const Orders = lazy(() => import("../components/Orders"));
const OrderDetails = lazy(() => import("../components/OrderDetails"));


const commonRoutes = [
  { path: "/", component: HomePage },
  {
    path: "/products/:categoryId/:currentPage/:categoryName",
    component: ProductListing
  },
  {
    path: "/product/:productId/:productName",
    component: SingleProduct
  },
  { path: "/cart", component: Cart },
  { path: "/contact-us", component: ContactUs },
  { path: "/pages/:page", component: CmsPage },
  { path: '/login', component: Login },
  { path: '/signup', component: SignUp },
  { path: '/forget-password', component: ForgetPassword },
];

const authProtectedRoutes = [
  { path: "/profile", component: Profile },
  { path: "/shipping", component: Shipping },
  { path: "/checkout/:orderId", component: Checkout },
  { path: "/change-password", component: ChangePassword },
  { path: "/orders", component: Orders },
  { path: "/order-details/:orderId", component: OrderDetails }

];

const publicRoutes = [
  // { path: '/login', component: Login },
  { path: '/signup', component: SignUp },
  { path: '/forget-password', component: ForgetPassword }
];

export { authProtectedRoutes, publicRoutes, commonRoutes };
