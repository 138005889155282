import React from "react";
import { Link } from "react-router-dom";
import makeStyle from "./styles";
import { getCategoriesByParentName } from "../../../helpers/common";
import CategoryBox from "./CategoryBox";
import ProductBox from "./ProductBox";

const DropdownMenuWeb = ({ topMenus, categories, labeledProducts }) => {
  const classes = makeStyle();

  return (
    <div className={classes.webMenus}>
      <ul className="d-flex mb-0">
        {topMenus.map((menu, index) => (
          <li key={index} className={`menu-dropdown ${classes.dropdown}`}>
            <Link
              className={`nav-link ${classes.dropdownLink}`}
              key={`top-menu-${menu.id}`}
              to={menu.url}
            >
              {menu.name}
            </Link>
            {getCategoriesByParentName(categories, menu.name)?.length > 0 ||
            labeledProducts[menu.name]?.length > 0 ? (
              <div className={`dropdown-content ${classes.dropdownContent}`}>
                <div className={`row ${classes.block} m-0`}>
                  {menu.url.includes("#labels/")
                    ? labeledProducts[menu.name]?.map((product, index) => (
                        <ProductBox
                          key={"product-" + index}
                          product={product}
                        />
                      ))
                    : getCategoriesByParentName(categories, menu.name)?.map(
                        (category, index) => (
                          <CategoryBox
                            key={"category" + index}
                            category={category}
                          />
                        )
                      )}
                </div>
              </div>
            ) : null}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default DropdownMenuWeb;
