import {
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_FAILURE,
  GET_HOT_PRODUCTS_SUCCESS,
  GET_HOT_PRODUCTS_FAILURE,
  GET_OFFER_PRODUCTS_SUCCESS,
  GET_OFFER_PRODUCTS_FAILURE,
  GET_LABELED_PRODUCTS_SUCCESS,
  GET_LABELED_PRODUCTS_FAILURE,
} from '../constants/actionTypes';

const INITIAL_STATE = {
  data: [], // this is used to store the list of the products
  product: {}, // this is used to store the single product
  error: '',
  hotProducts: [],
  offerProducts: [],
  labeledProducts: {
  },
};

export default function products(state = INITIAL_STATE, action) {
  switch (action.type) {
    case GET_PRODUCTS_SUCCESS:
      return { ...state, data: action.payload.data };
    case GET_PRODUCTS_FAILURE:
      return {
        ...state, error: action.payload.error
      }
    case GET_SINGLE_PRODUCT_SUCCESS:
      return { ...state, product: action.payload.data };
    case GET_SINGLE_PRODUCT_FAILURE:
      return {
        ...state, error: action.payload.error
      }
      case GET_HOT_PRODUCTS_SUCCESS:
        return {
          ...state, hotProducts: action.payload.data
        }
      case GET_HOT_PRODUCTS_FAILURE:
        return {
          ...state, error: action.payload.error
        }
      case GET_OFFER_PRODUCTS_SUCCESS:
      return {
        ...state, offerProducts: action.payload.data
      }
      case GET_OFFER_PRODUCTS_FAILURE:
        return {
          ...state, error: action.payload.error
        }
      case GET_LABELED_PRODUCTS_SUCCESS:
      return {
        ...state,
        labeledProducts: {
          ...state.labeledProducts,
          [action.payload.label]: action.payload.data
        }
      }
    case GET_LABELED_PRODUCTS_FAILURE:
      return {
        ...state, error: action.payload.error
      }
    default:
      return state;
  }
}
