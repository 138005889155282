import React, { lazy, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import {
  authProtectedRoutes,
  commonRoutes,
} from "../../../routes";
import ErrorBoundary from "../../ErrorBoundary";
import AppRoute from "../../HOC/AppRoute";
import '../../../index.css';

const NotFound = lazy(() => import("../../NotFoundPage"));

const MainContent = () => {
  return (
    <main>
      <ErrorBoundary>
        <Suspense fallback={<div className="inner-loader">
          <img src="/images/inner_loader.gif" alt="loader" />
        </div>}>
          <Switch>
            {commonRoutes.map((route) => (
              <Route
                exact
                path={route.path}
                component={route.component}
                key={route.path}
              />
            ))}
            {authProtectedRoutes.map((route) => (
              <AppRoute
                exact
                path={route.path}
                isAuthProtected={true}
                component={route.component}
                key={route.path}
              />
            ))}
            {/* {publicRoutes.map((route) => (
              <AppRoute
                exact
                path={route.path}
                component={route.component}
                key={route.path}
              />
            ))} */}
            <Route component={NotFound} />
          </Switch>
        </Suspense>
      </ErrorBoundary>
    </main>
  );
};

export default MainContent;
