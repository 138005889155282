import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { Link } from 'react-router-dom';


const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
    marginTop: '10px',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles()(MenuItem);

export default function AccountDropdown() {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <Link
        to="#"
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}
      >
         <i className='fa fa-user' /> Account
      </Link>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        <StyledMenuItem className="menu-dropdown">
       <Link to="/profile"> My Profile</Link>
        </StyledMenuItem>
        <StyledMenuItem className="menu-dropdown">
       <Link to="/orders"> My Orders</Link>
        </StyledMenuItem>
        <StyledMenuItem className="menu-dropdown">
        <Link to="/change-password">Change Password</Link>
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
