import React from "react";
import { Link } from "react-router-dom";
import makeStyle from "./styles";
import { convertToSlug, imageUrl } from "../../../helpers/common";

const CategoryBox = ({ product }) => {

    const classes = makeStyle();
    const link = `/product/${product.id}/${convertToSlug(product.name)}`;

    return <Link to={link}>
        <div
            className={`categories-box ${classes.categoriesBox}`}
        >
            <img
                className={classes.boxImage}
                src={imageUrl(product.main_image, `products/${product.id}` )}
                alt="product-img"
            />
            <p className={classes.productName}>
                {product.name}
            </p>
            <p className={classes.productPrice}>&#x20B9;{product.selling_price}</p>
        </div>
    </Link>

}

export default CategoryBox;