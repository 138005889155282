import React from "react";
import { useSelector } from "react-redux";
import Footer from "./Footer";
import Header from "./Header";
import MainContent from "./MainContent";
import CustomSnackbar from '../Common/CustomSnackbar';

function Layout() {
  const snackbarProp = useSelector((state) => state.snackbar);

  return (
    <div>
      <Header />
      <MainContent />
      <Footer />
      {<CustomSnackbar
        open={snackbarProp.open}
        message={snackbarProp.message}
        severity={snackbarProp.severity}
        action={snackbarProp.action}
      />}
    </div>
  );
}

export default Layout;
