import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getContents } from "../../../actions/contents";
import { loadCartData } from "../../../actions/cart";
import { getMenus } from "../../../actions/menus";
import { getSettings } from "../../../actions/settings";
import { Link } from 'react-router-dom';
import { getSettingByKey } from "../../../helpers/common";
import striptags from 'striptags';
import { getCategory } from "../../../actions/categories";
import { getAssets } from "../../../actions/assets";

export default function Index() {
  const dispatch = useDispatch();
  const menus = useSelector((state) => state.menus.menus);

  const settings = useSelector((state) => state.settings.data);

  // only getting footer-main-menu
  let footerMainMenu = menus.filter(menu => menu.category === 'footer-main-menu');

  // only getting footer-products-menu
  let footerProductsMenu = menus.filter(menu => menu.category === 'footer-products-menu');

  // only getting footer-other-links
  let footerOtherLinks = menus.filter(menu => menu.category === 'footer-other-links');

  // only getting company_contact_number
  const company_contact_number = getSettingByKey(settings, "company_contact_number");

  // only getting company_email
  const company_email_address = getSettingByKey(settings, "company_email_address");

  // only getting company_address
  const company_address = getSettingByKey(settings, "company_address", {});

  // only getting copyright
  const footer_copyright_message = getSettingByKey(settings, "footer_copyright_message");

  useEffect(() => {
    dispatch(getMenus());
    dispatch(getSettings());
    dispatch(getContents());
    dispatch(getCategory());
    dispatch(getAssets());
    dispatch(loadCartData()); // to load cart data from the local storage
  }, [dispatch]);


  return (
    <footer>
      <div className='footer_detail'>
        <div className='container'>
          <div className='row'>
            <div className='col-xl-4 col-lg-4 col-md-4 col-sm-12'>
              <div className='add_info'>
                <i className='fa fa-phone' />
                <strong>Contact Us</strong>
                {company_contact_number?.value}
              </div>
              <div className='add_info'>
                <i className='fa fa-envelope' />
                <strong>Email</strong>
                <a href={`mailto:${company_email_address?.value}`}>{company_email_address?.value}</a>
              </div>
              <div className='add_info'>
                <i className='fa fa-map-marker' />
                <strong>Address</strong>
                {striptags(company_address?.value)}
              </div>
            </div>
            <div className='col-xl-8 col-lg-8 col-md-8 col-sm-12'>
              <div className='row'>
                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4'>
                  <h3>Main Menu</h3>
                  <ul className='footer_links'>
                    {footerMainMenu.map(mainMenu => (<li key={`footer-menu-${mainMenu.id}`} >
                      <Link to={mainMenu.url}>{mainMenu.name}</Link>
                    </li>))}
                  </ul>
                </div>
                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6'>
                  <h3>Products</h3>
                  <ul className='footer_links'>
                    {footerProductsMenu.map(productMenu => <li key={`product-menu-${productMenu.id}`} >
                      <Link to={productMenu.url} >{productMenu.name}</Link>
                    </li>)}
                  </ul>
                </div>
                <div className='col-xl-4 col-lg-4 col-md-4 col-sm-4 col-6'>
                  <h3>Other Links</h3>
                  <ul className='footer_links'>
                    {footerOtherLinks.map(otherLinks => (<li key={`other-links-${otherLinks.id}`}>
                      <Link to={otherLinks.url} >{otherLinks.name}</Link>
                    </li>))}
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='copyright'>{footer_copyright_message?.value}</div>
        </div>
      </div>
    </footer>
  );
}
