import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles((theme) => ({
  sideBarMenuFull: {
    position: "fixed",
    right: 0,
    top: 0,
    height: "100%",
    zIndex: 100,
    width: 0,
    backgroundColor: "rgba(0,0,0,0.5)",
    opacity: 0,
  },
  sideBarMenuFullActive: {
    width: '100%',
    opacity: 1,
  },
  close: {
    textAlign: 'left',
    marginBottom: '10px',
  },
  sideBarMenu: {
    position: "fixed",
    right: 0,
    top: 0,
    height: "100%",
    zIndex: 100,
    width: 0,
    textAlign: "center",
    overflowX: "hidden",
    paddingTop: "40px",
    paddingBottom: "30px",
    backgroundColor: "#fff",
    opacity: 0,
    transition: "all 0.5s ease-in-out",
    boxShadow: `0px 4px 16px -6px #000`,
  },
  sideBarMenuActive: {
    width: 400,
    opacity: 1,
    '@media (max-width: 450px)': {
      width: '85%',

    }
  },
  root: {
    borderBottom: "1px solid #e5e5e5",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  subCategoryImage: {
    width: 60,
    height: 60,
    marginRight: 10,
    border: "1px solid #e5e5e5",
  },
  dropdownLink: {
    paddingRight: "0 !important",
    paddingLeft: "0 !important",
    fontSize: "15px !important",
  },
  productName: {
    marginBottom: 0,
    fontSize: 13,
    lineHeight: "15px",
    marginTop: 5,
  },
  productPrice: {
    color: "#eaaa64",
    fontSize: 13,
    lineHeight: "15px",
    marginTop: 5,
  },
  categoryName: {
    fontSize: 13,
    lineHeight: "18px",
    marginTop: 5,
  },
}));

export default useStyles;
