import { combineReducers } from "redux";

import menus from "./menus";
import categories from "./categories";
import products from "./products";
import snackbar from "./snackbar";
import banners from "./banners";
import contents from "./contents";
import settings from "./settings";
import customers from "./customer";
import orders from "./orders";
import testimonials from "./testimonials";
import meta from "./meta";
import cart from "./cart";
import assets from "./assets";

const appReducers = combineReducers({
  menus,
  categories,
  snackbar,
  products,
  banners,
  contents,
  settings,
  customers,
  orders,
  testimonials,
  cart,
  meta,
  assets
});

export default appReducers;
