import { SET_META_DATA } from '../constants/actionTypes';

const defaultState = {
    data: {
        title: '',
        breadcrumb: '',
    }
};

export default function (state = defaultState, action) {
    switch (action.type) {
        case SET_META_DATA:
            return { ...state, data: action.payload.meta };
        default:
            return state;
    }
}
