

export const apiUrl = 'https://shop.metcraftchitra.com/backend/api/web';
export const dataUrl = 'https://shop.metcraftchitra.com/backend/data';
export const capitalize = (str) => `${str.charAt(0).toUpperCase() + str.substr(1).toLowerCase()}`;

export const sortFn = ((el1, el2, sortKey = 'display_order') => el1[sortKey] > el2[sortKey] ? 1 : -1);

export const imageUrl = (url, prefix = 'products', sizePrefix = '120x120-') => `${dataUrl}/${prefix}/${sizePrefix}${url}`;

export const productImageUrl = (url, prefix = 'products', id = "1", sizePrefix = '120x120-') => `${dataUrl}/${prefix}/${id}/${sizePrefix}${url}`;

export const testimonialimageUrl = (url, prefix = 'products') => `${dataUrl}/${prefix}/${url}`;

export const convertToSlug = (text) => text.toLowerCase().replace(/[^\w ]+/g, '').replace(/ +/g, '-')

export const getContentByName = (contents, name, defaultValue) => contents.find(content => content.name === name) || defaultValue;

export const getContentByCategoryName = (contents, categoryName) => contents.filter(content => content.category === categoryName);

export const getSettingByKey = (settings, keyName) => settings.find(setting => setting.key === keyName);

export const getMenusByCategory = (menus, categoryName) => menus.filter(menu => menu.category === categoryName);

export const getCategoryByName = (categories, name) => {
    return categories.find(category => category.name.toLowerCase() === name.toLowerCase());
}

export const getCategoryByParentId = (categories, parentId) => categories.filter(category => parseInt(category.parent_id) === parseInt(parentId) );

export const getCategoriesByParentName = (categories, parentName) => {
    let parentId = getCategoryByName(categories, parentName)?.id;
    return categories.filter(category => parseInt(category.parent_id) === parseInt(parentId));
}

export const getTestimonialByName = (clienttestimonials, company, defaultValue) => clienttestimonials.find(clienttestimonial => clienttestimonial.company === company) || defaultValue;

export const getTestimonialByCompanyName = (clienttestimonials, companyName) => clienttestimonials.filter(clienttestimonial => clienttestimonial.company === companyName);

// it is used to make the given string into array by splitting using the given seperator.
export const toArray = (value, seperator) => {

    let arr = [];

    if (value) {
        arr = value.split(seperator).filter(Boolean).map(v => v.trim()).filter(v => v !== "");
    }

    return arr;
}

export const getCategoryLink = (category) => `/products/${category.id}/1/${category.url_slug}`;

export const customerId_local_storage_key = 'metcraftchitra_customerId';
export const authKey_local_storage_key = 'metcraftchitra_authToken';
export const cartData_local_storage_key = 'metcraftchitra_cart_data';
export const userInfo_local_storage_key = 'metcraftchitra_userInfo';
