import { makeStyles } from "@material-ui/core/styles";
import colors from "../../../helpers/colors";

export default makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    "& *": { fontFamily: "HK GROTESK" },
    "& a": { textDecoration: "none", color: "inherit" },
  },

  appContainer: { width: "100%", margin: "0 auto", maxWidth: 1200 },

  topPhoneNumbers: {
    "& a": { fontFamily: "Raleway" },
  },

  toolbar: { backgroundColor: "#f7f7f7", minHeight: 120 },
  navItems: {
    margin: "0 24px",
    textTransform: "uppercase",
    fontFamily: "Raleway",
    fontWeight: 700,
  },
  verticalNavItems: {
    padding: "16px 24px",
    textTransform: "uppercase",
    textAlign: "center",
  },
  menuButton: {
    display: "none",
    fontFamily: "Raleway",
    fontWeight: 700,
    [theme.breakpoints.down("md")]: { display: "block", marginLeft: 10 },
    "&:hover": { color: colors.primary },
  },
  whiteButton: {
    backgroundColor: "#fff",
    borderRadius: 25,
    margin: "0 20px",
    textTransform: "none",
    color: colors.primary,
    fontWeight: "bold",
  },
  banner: {
    color: "#d6a65d",
    fontSize: 30,
    letterSpacing: 10,
    fontWeight: 600,
  },

  icon: {
    color: "#fff",
    "&.active": { color: "#dddddd" },
  },

  badge: { backgroundColor: "#fff", color: colors.primary },

  avatar: {
    color: colors.primary,
    cursor: "pointer",
    backgroundColor: "#fff",
    width: 45,
    height: 45,
    margin: 5,
    border: "2px solid #d6a65d",
  },

  expandedMenu: { [theme.breakpoints.down("md")]: { display: "none" } },

  box: { "& > *": { margin: "0 5px" }, alignItems: "center" },
  link: {
    fontFamily: "Raleway",
    fontWeight: 700,
    textDecoration: "none",
    color: "#666",
    "&.active, &:hover": { color: colors.primary },
  },
  lowerMenuLink: { margin: "0 16px" },
  searchInput: {
    padding: "6px 16px",
    backgroundColor: colors.primary,
    border: "2px solid #fff",
    position: "absolute",
    top: 30,
    left: "36%",
    zIndex: 9999,
    borderRadius: 24,
    "& input": {
      width: 300,
      fontFamily: "Raleway",
      color: "#fff",
    },
  },
  webMenus: {
    "@media (max-width: 991px)": {
      display: "none",
    },
  },
  mobileMenus: {
    display: "none",
    "@media (max-width: 991px)": {
      display: "block",
    },
  },
  dropdownContent: {
    position: "absolute",
    backgroundColor: "#fff",
    zIndex: 9999,
    right: "6.5%",
    padding: "20px",
    opacity: 0,
    visibility: "hidden",
    width: "58%",
    transform: "translateY(30px)",
    transition: "transform .35s linear",
    boxShadow: "0px 8px 10px rgba(0, 0, 0, 0.1)",
    pointerEvents: "none",
  },
  block: {
    display: "flex",
    flexWrap: "wrap",
  },
  categoriesBox: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    paddingRight: "20px",
    maxWidth: "160px",
    textAlign: "center",
    "&:hover img": {
      color: "#eaaa64",
      border: "1px solid #eaaa64",
      transition: "all .3s ease-in-out",
    },
  },
  boxImage: {
    border: "1px solid #e5e5e5",
    transition: "all .3s ease-in-out",
  },
  root: {
    borderBottom: "1px solid #e5e5e5",
    "&:last-child": {
      borderBottom: "none",
    },
  },
  nested: {
    paddingLeft: theme.spacing(4),
  },
  subCategoryImage: {
    width: 60,
    height: 60,
    marginRight: 10,
    border: "1px solid #e5e5e5",
  },
  dropdownLink: {
    paddingRight: "0 !important",
    paddingLeft: "0 !important",
    fontSize: "15px !important",
  },
  productName: {
    marginBottom: 0,
    fontSize: 13,
    lineHeight: "15px",
    marginTop: 5,
  },
  productPrice: {
    color: "#eaaa64",
    fontSize: 13,
    lineHeight: "15px",
    marginTop: 5,
  },
  categoryName: {
    fontSize: 13,
    lineHeight: "18px",
    marginTop: 5,
  }
}));
