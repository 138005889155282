import { createAction } from 'redux-actions';
import axios from "axios";
import {
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAILURE,
  SUBMIT_REVIEW_SUCCESS,
  SUBMIT_REVIEW_FAILURE,
  GET_REVIEWS_SUCCESS,
  GET_REVIEWS_FAILURE,
  GET_SINGLE_PRODUCT_SUCCESS,
  GET_SINGLE_PRODUCT_FAILURE,
  GET_HOT_PRODUCTS_SUCCESS,
  GET_HOT_PRODUCTS_FAILURE,
  GET_OFFER_PRODUCTS_SUCCESS,
  GET_OFFER_PRODUCTS_FAILURE,
  GET_LABELED_PRODUCTS_SUCCESS,
  GET_LABELED_PRODUCTS_FAILURE,
} from '../constants/actionTypes';
import { getAllReviews, getItemList, getProduct, postReview } from '../api/items'
import { showSuccessSnackbar } from './snackbar';
import { dataUrl } from '../helpers/common';

const getProductsSuccess = createAction(GET_PRODUCTS_SUCCESS);
const getProductsFailure = createAction(GET_PRODUCTS_FAILURE);

export function getProducts(queryParamsObj, perPage, page) {
  return dispatch => getItemList('products', queryParamsObj, perPage, page)
    .then((json) => dispatch(getProductsSuccess({
      ...json,
      productLabels: queryParamsObj.product_labels,
    })))
    .catch(error => dispatch(getProductsFailure(error)))
}

const getSingleProductSuccess = createAction(GET_SINGLE_PRODUCT_SUCCESS);
const getSingleProductFailure = createAction(GET_SINGLE_PRODUCT_FAILURE);

export function getSingleProduct(productId) {
  return dispatch => getProduct(productId)
    .then((json) => dispatch(getSingleProductSuccess({
      ...json,
    })))
    .catch(error => dispatch(getSingleProductFailure(error)))
}

export const getSinglProduct = async (id) => {
  return axios.get(
    `${dataUrl}/products/` + id
  );
};

const submitReviewSuccess = createAction(SUBMIT_REVIEW_SUCCESS);
const submitReviewFailure = createAction(SUBMIT_REVIEW_FAILURE);

export function submitNewReview(params, customer) {
  console.log('customer', customer);
  return dispatch => postReview(params)
    .then(data => {
      dispatch(showSuccessSnackbar('Your review has been submitted'));
      dispatch(submitReviewSuccess({ ...params, customer }));
      return true;
    })
    .catch(error => {
      dispatch(submitReviewFailure(error));
      return false;
    });
}

const getReviewsSuccess = createAction(GET_REVIEWS_SUCCESS);
const getReviewsFailure = createAction(GET_REVIEWS_FAILURE);

export function getReviews(productId) {
  return dispatch => getAllReviews(productId)
    .then(data => dispatch(getReviewsSuccess(data)))
    .catch(error => dispatch(getReviewsFailure(error)));
}

const getHotProductsSuccess = createAction(GET_HOT_PRODUCTS_SUCCESS);
const getHotProductsFailure = createAction(GET_HOT_PRODUCTS_FAILURE);

export function getHotProducts(queryParamsObj, perPage, page) {
  return dispatch => getItemList('products', queryParamsObj, perPage, page)
    .then((json) => dispatch(getHotProductsSuccess({
      ...json,
    })))
    .catch(error => dispatch(getHotProductsFailure(error)))
}

const getOfferProductsSuccess = createAction(GET_OFFER_PRODUCTS_SUCCESS);
const getOfferProductsFailure = createAction(GET_OFFER_PRODUCTS_FAILURE);

export function getOfferProducts(queryParamsObj, perPage, page) {
  return dispatch => getItemList('products', queryParamsObj, perPage, page)
    .then((json) => dispatch(getOfferProductsSuccess({
      ...json,
    })))
    .catch(error => dispatch(getOfferProductsFailure(error)))
}



const getLabeledProductsSuccess = createAction(GET_LABELED_PRODUCTS_SUCCESS);
const getLabeledProductsFailure = createAction(GET_LABELED_PRODUCTS_FAILURE);

export function getLabeledProducts(label, perPage, page) {
  return dispatch => getItemList('products', `ProductsSearch[product_labels]=${label}`, perPage, page)
    .then((json) => dispatch(getLabeledProductsSuccess({
      ...json,
      label,
    })))
    .catch(error => dispatch(getLabeledProductsFailure(error)))
}